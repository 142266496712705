import React, {useContext} from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Link from 'components/link'
import { MainContext } from "context/context"
import Button from 'components/button'

export default () => {
    const {
        options,
        openModal
    } = useContext(MainContext)
    
    const data = useStaticQuery(graphql`
        query footerMenuQuery {
            siteFields {
                menu(id: "TWVudToxMg==") {
                    menuItems {
                        nodes {
                            label
                            url
                            connectedObject {
                                ... on WPGraphQL_Post {
                                    slug
                                    title
                                }
                                ... on WPGraphQL_MenuItem {
                                    title
                                }
                                ... on WPGraphQL_Category {
                                    slug
                                    name
                                }
                                ... on WPGraphQL_Page {
                                    slug
                                    title
                                }
                            }
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                        connectedObject {
                                            ... on WPGraphQL_Post {
                                                slug
                                                title
                                            }
                                            ... on WPGraphQL_MenuItem {
                                                title
                                            }
                                            ... on WPGraphQL_Category {
                                                slug
                                                name
                                            }
                                            ... on WPGraphQL_Page {
                                                slug
                                                title
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
              }
        }
    `)    

    const menuItems = data.siteFields.menu.menuItems.nodes

    return (
        <footer>
            <div className="container">
                <div className="top-row">
                    <img src={options.logos.inverted.sourceUrl} alt="Strikeforce" className="light" />
                    <div className="links">
                        <ul>
                            {menuItems.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <Link 
                                            to={item.url}
                                            dangerouslySetInnerHTML={{ __html: item.label }}
                                        />
                                    </li>
                                )
                            })}
                        </ul>
                        <ul>
                            <li>
                                <Link to="/career-opportunities">
                                    Careers Hub
                                </Link>
                            </li>
                            <li>
                                <Button 
						            onClick={_ => openModal({type: 'SCOPE_PROJECT'})} 
                                    label="Scope A Project" 
                                    type="button" 
                                />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="bottom-row">
                    <div className="left">
                        <span>
                            Copyright &copy; 2024 Strikeforce. All rights reserved.
                        </span>
                        <div>
                            <Link to={'/terms-and-conditions'}>
                                Terms and Conditions
                            </Link>
                            <Link to={'/privacy-policy'}>
                                Privacy Policy
                            </Link>
                        </div>
                        <span className='attribution'>
                            Website by <a href="https://yourcreative.com.au" target="_blank">Your Creative</a>
                        </span>
                    </div>
                    <div className="right">
                        <span>
                            <a href={options.linkedinLink.url} target="_blank" className="linkedIn" rel="noopener noreferrer">
                                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn icon</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    )
}
