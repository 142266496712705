import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Link from 'components/link'

export default ({
    closeModal
}) => {
    const modalQuery = useStaticQuery(graphql`
        query {
            siteFields {
                options {
                    options {
                        modalHeading
                        modalCopy
                        modalTiles {
                            formPage {
                                ... on WPGraphQL_Page {
                                    link
                                }
                            }
                            title
                            copy
                            buttonLabel
                            completionTime
                        }
                    }
                }
            }
        }
    `)

    const {
        modalHeading,
        modalCopy,
        modalTiles = [],
    } = modalQuery.siteFields.options.options

    const isMobile = typeof window !== 'undefined' && window.innerWidth <= 500

    return (
        <div className="content">
            { isMobile &&
                <div className="button-row">
                    <button 
                        onClick={closeModal}
                        className="close"
                    >
                        &times;
                    </button>
                </div>
            }
            <h1>
                { modalHeading }
            </h1>
            <p>
                { modalCopy }
            </p>
            <ul className="tiles">
                { modalTiles.map((tile, index) => {
                    const {
                        formPage: {
                            link,
                        },
                        completionTime,
                        copy,
                        title,
                        buttonLabel
                    } = tile

                    return (
                        <li 
                            key={index}
                            onClick={e => {
                                e.stopPropagation()

                                if(typeof window !== 'undefined'){
                                    window.location.href = link
                                }

                                setTimeout(() => {
                                    closeModal()
                                },100)
                            }}
                        >
                            <Link to={link}>
                                <h2>
                                    {title}
                                </h2>
                                { completionTime ?
                                    <span className="completion-time">
                                        (Takes about {completionTime})
                                    </span>
                                : null }
                                <p dangerouslySetInnerHTML={{ __html: copy }} />
                                <div className="button link arrow">
                                    { buttonLabel }
                                </div>
                                <div className="triangle" />
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}