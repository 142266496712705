import axios from 'axios'

export const emailIsValid = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase())
}

export const nl2br = str => {
    if (typeof str === 'undefined' || str === null) {
        return ''
    }
    let breakTag = '<br />'

    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

export const isMobile = typeof window !== 'undefined' && window.innerWidth <= 500

export const getJob = id => {
    return axios({
        method: 'get',
        url: `https://applynow.net.au/api/v2/jobs/${id}?recursive=true&current=true`,
        headers: {
            'Content-Type': 'application/json',
            'X-api-authenticate': 'b76c95cd32e88997f275c72cef11bc08dbdf907f'
        }
    })
}