import React, { useState, useContext, useEffect } from 'react'
import { MainContext } from "context/context"
import { useStaticQuery, graphql } from "gatsby"
import Link from 'components/link'
import Button from 'components/button'
import { isMobile } from 'utils'

export default ({ 
    isOpen,
    location
}) => {
	const {
        openModal,
        isMobileServicesForcedOpen
    } = useContext(MainContext)
    
   const data = useStaticQuery(graphql`
        query menu {
            siteFields {
				services {
					edges {
						node {
							slug
							title
						}
					}
				}
                menu(id: "TWVudTo0") {
                    id
                    name
                    menuItems {
                        nodes {
                            childItems {
                                nodes {
                                    label
                                    url
                                }
                            }
                            label 
                            url
                        }
                    }
                }
            }
        }
    `)

    const {
        services: {
            edges: services
        },
        menu: {
            menuItems: {
                nodes: menuItems
            }
        }
    } = data.siteFields

    const [expandedItemIndex, setExpandedItemIndex] = useState(null)
    const [windowHeight, setWindowHeight] = useState(null)

    const allServices = services.map(({ node: service }) => {
        return {
            url: `/services/${ service.slug }`,
            label: service.title
        }
    })

    useEffect(() => {
        if(isMobileServicesForcedOpen){
            setExpandedItemIndex({
                index: 1,
                items: allServices
            })
        }
    }, [isMobileServicesForcedOpen])

    useEffect(() => {
        let _windowHeight = null

        if (typeof window !== 'undefined') {
            const windowWidth = window.screen.width
    
            _windowHeight = window.screen.height
    
            if(windowWidth <= 500){
                _windowHeight *= (500/windowWidth)
            }
        }

        setWindowHeight(_windowHeight)
    }, [])

    const subMenuItems = expandedItemIndex ? expandedItemIndex.items : []

    const { pathname } = location

    const _pathname = pathname.replace('/','')

    return (
        <div 
            className={`menu ${ isOpen ? 'visible' : ''} ${subMenuItems.length ? 'sub-open' : ''}`}
            onClick={() => setExpandedItemIndex(null)}
            style={{height: isMobile ? windowHeight+'px' : undefined}}
        >
            <div className="container">
                <div className="menu-scroll" style={{
                    height: isMobile ? windowHeight+'px' : undefined
                }}>
                    <ul>
                        {menuItems.map((row, index) => {
                            const {
                                url,
                                label,
                                childItems: {
                                    nodes: childItems = []
                                }
                            } = row

                            const isServices = url.indexOf('services') !== -1

                            const hasChildItems = !!childItems.length || (isMobile && isServices)

                            const _childItems = (isMobile && isServices) ? allServices : childItems

                            const isActive = _pathname && url.indexOf(_pathname) !== -1

                            return (
                                <li 
                                    key={index}
                                    className={`${expandedItemIndex && expandedItemIndex.index === index ? 'sub-active' : ''} ${ isActive ? 'active' : ''} ${ hasChildItems ? 'has-children' : ''}`}
                                >
                                    <h1>
                                        { !hasChildItems ?
                                            <Link 
                                                to={url} 
                                                dangerouslySetInnerHTML={{ __html: label }} 
                                            />                                            
                                        :
                                            <button onClick={e => {
                                                e.stopPropagation()
                                                setExpandedItemIndex({
                                                    index, 
                                                    items: _childItems
                                                })
                                            }}>
                                                { label }
                                                <i className="fe fe-chevron-right" />
                                            </button>
                                        }
                                    </h1>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div 
                    className="menu-scroll-sub"
                    style={{
                        height: isMobile ? windowHeight+'px' : undefined
                    }}
                >
                    <ul>
                        { subMenuItems.length ? 
                            <li className="small">
                                <button onClick={() => setExpandedItemIndex(null)}>
                                    <i className="fe fe-arrow-left" />
                                    Go back
                                </button>
                            </li>
                        : null }
                        { subMenuItems.map((row, index) => {
                            const {
                                url,
                                label
                            } = row

                            const isActive = _pathname && _pathname.indexOf(url.replace('/','').replace('services','')) !== -1

                            return (
                                <li 
                                    key={index}
                                    onClick={e => e.stopPropagation()}
                                    className={isActive ? 'active' : ''}
                                >
                                    <Link 
                                        to={url}
                                        dangerouslySetInnerHTML={{ __html: label}}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="tri">
                    <div className="person" />
                    <Button 
                        onClick={_ => openModal({type: 'SCOPE_PROJECT'})} 
                        label="Start a project" 
                        type="button" 
                    />
                </div>
            </div>
            <div className="tri" />
        </div>
    )
}
