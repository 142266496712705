import gql from 'graphql-tag'

export const GET_FORM = gql`
    query GET_FORM(
        $id: ID!
    ) {
        form (
            id: $id
        ) {
            formFields {
                submissionTitle
                sections {
                    title
                    rowsHeading
                    rows {
                        columns {
                            label
                            subtitle
                            options
                            type
                            services {
                                icon {
                                    sourceUrl
                                }
                                label
                            }
                            integratedSolutionsPopUp {
                                icon {
                                    sourceUrl
                                }
                                copy
                            }
                        }
                        onlyShowIf
                    }
                }
            }
        }
    }
`

export const GET_POSTS_BY_CATEGORY = gql`
    query GET_POSTS_BY_CATEGORY (
        $categoryName: String
        $searchTerm: String
    ) {
        posts (
            where: {
                categoryName: $categoryName
                search: $searchTerm
            }
        ) {
            nodes {
                title
            }
            edges {
                node {
                    link
                    title
                    excerpt
                    date
                    featuredImage {
                        mediaItemUrl
                    }
                    categories {
                        nodes {
                            name
                            slug
                        }
                    }
                    tags {
                        nodes {
                            name
                            slug
                        }
                    }
                }
            }
        }
    }
`

export const SUBMIT_FORM = gql`
    mutation SUBMIT_FORM (
        $data: String
        $type: String!
        $recipients: String
        $jobtitle: String
    ) {
        submitForm (
            input: {
                data: $data
                recipients: $recipients
                type: $type
                jobtitle: $jobtitle
                clientMutationId: "uniqueId"
            }
        ) {
            success
        }
    }
`

export const GET_SEARCH_RESULTS = gql`
    query GET_SEARCH_RESULTS (
        $term: String
    ) {
        contentNodes(where: {search: $term}) {
            nodes {
                __typename
                id
                link
                uri
                ... on Post {
                    title
                }
                ... on Service {
                    title
                }
                ... on Page {
                    title
                }
            }
        }
    }
`