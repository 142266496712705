import React, { useEffect, useRef } from 'react'

export default ({
    videoTitle,
    video,
    closeModal,
    isVisible
}) => {
    const videoRef = useRef(null)

    useEffect(() => {
        if(videoRef.current){
            videoRef.current.pause()
        }
    }, [isVisible])

    return (
        <div 
            className="content"
            onClick={e => e.stopPropagation()}
        >
            <div className="close" onClick={_ => closeModal()}>
                &times;
            </div>
            <h3>
                {videoTitle}
            </h3>
            <video controls autoPlay ref={ref => videoRef.current = ref}>
                <source src={video} type="video/mp4" />
            </video>
        </div>
    )
}