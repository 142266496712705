import React, { useContext } from 'react'
import Link from 'components/link'
import { isMobile } from 'utils'

import { MainContext } from 'context/context'

export default ({ 
    label, 
    url = '',
    onClick, 
    showArrow,
    type,
    big = '',
    className = '',
    isLink = true,
}) => {
    const {
        setIsMobileServicesForcedOpen
    } = useContext(MainContext)

    const buttonLabel = label || 'Read more'
    
    let linkClass = `${ showArrow !== 'false' ? 'arrow' : '' } ${ className } ${ big }`
    
    if (showArrow === 'hover'){
        linkClass += ' arrowHover'
    } else if (showArrow === 'left'){
        linkClass += ' arrowLeft'
    }

    const forceOpenServicesMenu = isMobile && url === '/services'
    const _url = forceOpenServicesMenu ? '#' : url
    
    if (type === 'button' && isLink) {
        return (
            <Link
                to={_url} 
                onClick={e => {
                    if(onClick){
                        e.preventDefault()
                        onClick()
                    }
                }} 
                className={'button '+linkClass}
            >
                { buttonLabel }
            </Link>
        )
    } else if (type === 'button' && !isLink) {
        return (
            <a
                onClick={e => {
                    if(onClick){
                        e.preventDefault()
                        onClick()
                    }
                }} 
                className={'button '+linkClass}
            >
                { buttonLabel }
            </a>
        )
    } else {
        return (
            <Link 
                to={_url} 
                className={'button link '+linkClass}
                onClick={() => {
                    if(forceOpenServicesMenu){
                        setTimeout(() => {
                            setIsMobileServicesForcedOpen(true)
                        },1)
                    }else if(onClick){
                        onClick()
                    }
                }}
            >
                { buttonLabel }
            </Link>
        )
    }
}
