import React from 'react'
//import AniLink from "gatsby-plugin-transition-link/AniLink"
import AniLink from 'vendor/AniLink'

export default ({
    children,
    to,
    dangerouslySetInnerHTML,
    className,
    onClick
}) => {
    if (!to) return null

    const _to = (() => {
        if (to.startsWith('http')) return to

        if (to.startsWith('/')) return to

        return '/' + to
    })()

    return (
        <AniLink
            cover
            duration={0.7}
            direction="left"
            bg="#003E8D"
            to={_to}
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            className={className}
            onClick={onClick ? onClick : undefined}
        >
            {children}
        </AniLink>
    )
}