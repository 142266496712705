import React from 'react'

import Header from './header'
import Footer from './footer'
import Modal from './modal'

export default ({ children, location }) => {
	return (
		<div className="page-container">
			<Header location={location}/>
			<main>
				{ children }
			</main>
			<Footer />
			<Modal/>
		</div>
  	)
}