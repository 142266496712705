import React, { useContext } from "react"
import { MainContext } from "context/context"

import Video from './modalTypes/video'
import Search from './modalTypes/search'
import Confirmation from './modalTypes/confirmation'
import Scope from './modalTypes/scope'

export default () => {
	const {
        modalData,
        closeModal
    } = useContext(MainContext)

    const {
        type,
        isVisible
    } = modalData

    //GENERAL STYLING
    let className = 'modal '

    if(modalData) {
        className += type

        if(isVisible) {
            className += ' visible'
        }
    }

    const modalProps = {
        ...modalData,
        closeModal
    }

    let component = null

    switch(modalData.type){
        case 'search':
            component = (
                <Search {...modalProps} />
            )
            break
        case 'video':
            component = (
                <Video {...modalProps} />
            )
            break
        case 'CONFIRMATION':
            component = (
                <Confirmation {...modalProps} />
            )
            break
        default:
            component = (
                <Scope 
                    {...modalProps} 
                />
            )
            break
    }

    return (
        <div 
            className={className}
            onClick={closeModal}
        >
            { component }
        </div>
    )
}