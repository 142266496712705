import React, { useContext } from 'react'
import Link from 'components/link'
import { MainContext } from "context/context"
import Button from 'components/button'

export default ({
    closeModal,
    subType,
    whitepaper,
}) => {
    const {
        options,
    } = useContext(MainContext)

    let copyKey = null
    let isError = false

    switch(subType){
        case 'SCOPE':
            copyKey = 'scopeProjectSuccess'
            break
        case 'CONTACT':
            copyKey = 'contactSuccess'
            break
        case 'JOB_NOT_FOUND':
            isError = true
            copyKey = 'jobNotFound'
            break
    }

    const {
        title,
        copy
    } = options[copyKey]

    return (
        <div className="box" onClick={e => e.stopPropagation()}>
            <div className="close" onClick={_ => closeModal()}>
                &times;
            </div>
            <div className="check-circle">
                <i className={`fe fe-${ isError ? 'x' : 'check' }`}></i>
            </div>
            <h4>
                { title }
            </h4>
            { whitepaper ?
                <div className="download-whitepaper">
                    Click the link below to download the Whitepaper:
                    <a
                        href={ whitepaper }
                        target="_blank"
                        className={'button big arrow'} 
                        download
                    >
                        Download Whitepaper now
                    </a>
                </div>
            :
                <>
                    <div className="copy" dangerouslySetInnerHTML={{ __html: copy }} />
                    { isError ?
                        <Button
                            label={'Okay'}
                            onClick={closeModal}
                            big={'big'}
                        />
                    :
                        <Link 
                            className={'button big arrow'} 
                            to={'/'}
                            onClick={closeModal}
                        >
                            Return to home page
                        </Link>
                    }
                </>
            }
        </div>
    )
}