import React, { useState, useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_SEARCH_RESULTS } from '../../graphql/queries'
import Link from 'components/link'

import { debounce } from 'lodash'

export default ({
    closeModal,
    isVisible
}) => {
    const [getSearchResults, { data: searchResults, loading, error }] = useLazyQuery(GET_SEARCH_RESULTS)
    const [searchTerm, setSearchTerm] = useState(null)
    const [results, setResults] = useState([])
    const [isSearching, setIsSearching] = useState(false)

    const searchTermRef = useRef(searchTerm)
    searchTermRef.current = searchTerm

    const textInput = useRef(null)

    const _handleChangeSearchTerm = term => {
        if (searchTermRef.current) {
            setIsSearching(true)

            getSearchResults({
                variables: {
                    term: searchTermRef.current
                },
            })
        } else {
            setResults([])
        }
    }

    useEffect(() => {
        if(isVisible){
            if(textInput.current){
                textInput.current.focus()
            }
        }
    }, [isVisible])

    const debouncedHandleChangeSearchTerm = useRef(debounce(_handleChangeSearchTerm, 300))

    const handleChangeSearchTerm = term => {
        console.log('handle search term')
        console.log(term)

        debouncedHandleChangeSearchTerm.current(term)
        setSearchTerm(term)
    }

    useEffect(() => {
        if (searchResults && !loading) {
            setIsSearching(false)

            setResults(searchResults)
        }
    }, [searchResults, loading])

    const _searchResults = results.contentNodes ? results.contentNodes.nodes.filter(node => {
        let hasValidPostType = true

        switch(node.__typename){
            case 'Form_Submission':
            case 'Form':
                hasValidPostType = false
                break
        }

        return hasValidPostType
    }) : []

    const hasResults = !!_searchResults.length

    return (
        <div className={`wrapper ${ hasResults ? 'has-results' : ''}`} onClick={e => e.stopPropagation()}>
            <div>
                <p>
                    What are you looking for?
                </p>
                <div className="input-container">
                    <input 
                        onChange={e => handleChangeSearchTerm(e.target.value)} 
                        placeholder={'Enter search term'}
                        ref={ref => textInput.current = ref}
                    />
                </div>
            </div>
            <ul>
                {hasResults ? _searchResults.map((result, index) => (
                    <li key={index} type={result.__typename}>
                        <Link
                            to={result.link}
                            onClick={closeModal}
                        >
                            <i className="fe fe-chevron-right" />
                            <h3 dangerouslySetInnerHTML={{ __html: result.title }} />
                            <p dangerouslySetInnerHTML={{ __html: result.__typename }} />
                        </Link>
                    </li>
                ))
                : null }
            </ul>
        </div>
    )
}