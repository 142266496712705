import React, { useContext, useState, useEffect } from "react"

import { MainContext } from "context/context"
import Menu from 'components/menu'
import Button from 'components/button'
import Link from 'components/link'

import { isMobile } from 'utils'

export default ({ location }) => {
	const {
		options,
		modalData,
		openModal,
		isHeaderInverted,
		hasScrolled,
		checkInvertedHeaderOffsets,
		resetHeader,
		isMobileServicesForcedOpen,
		setIsMobileServicesForcedOpen
	} = useContext(MainContext)

	useEffect(() => {
		resetHeader()

		if(!isMobileServicesForcedOpen){
			setMenuOpen(false)
		}

		setTimeout(() => {
			checkInvertedHeaderOffsets()
		}, 10)
	}, [location])

	useEffect(() => {
		if(isMobileServicesForcedOpen){
			setMenuOpen(true)
		}else{
			setMenuOpen(false)
		}
	}, [isMobileServicesForcedOpen])

	const [menuOpen, setMenuOpen] = useState(false)

	const closeMenu = () => {
		setIsMobileServicesForcedOpen(false)

		setMenuOpen(false)
	}

	const toggleMenu = () => {
		if(menuOpen){
			closeMenu()
		}else{
			setMenuOpen(true)
		}
	}

	let headerClassName = 'header'

	if (modalData.isVisible || isHeaderInverted) {
		headerClassName += ' inverted'
	}

	if(hasScrolled){
		headerClassName += ' has-scrolled'
	}

	return (
		<header id="header" className={headerClassName}>
			<div className="container">
				<Menu 
					isOpen={menuOpen} 
					location={location}
				/>
				<div className="header-main">
					<button className={menuOpen ? 'close' : 'open'} onClick={toggleMenu}>
						<div/>
						<span>{ menuOpen ? 'Close' : 'Menu'}</span>
					</button>
					{ isMobile ?
						<span>
							<Button 
								onClick={_ => openModal({type: 'SCOPE_PROJECT'})} 
								label="Scope project" 
								showArrow={'false'}
								type="button" 
								key={1}
								className={'test'}
							/>		
						</span>
					: null }
					<Link
						to="/" 
						className={menuOpen ? 'logo inverted' : 'logo'}
						key={2}
					>
						<img 
							src={options.logos.standard.sourceUrl} 
							alt="Strikeforce" 
						/>
						<img 
							src={options.logos.inverted.sourceUrl} 
							alt="Strikeforce" 
							className="light" 
						/>
					</Link>
				</div>
				<div className="header-right">
					<span>
						<button 
							className="header-search" 
							onClick={_ => openModal({type: 'search'})}
						/>
						<Button 
							label="Careers" 
							showArrow={'false'}
							type="button" 
							className={'outlined'}
							url={'/careers-hub'}
						/>
						<Button 
							onClick={_ => openModal({type: 'SCOPE_PROJECT'})} 
							label="Scope a project" 
							type="button" 
						/>
					</span>
				</div>
			</div>
		</header>

	)
}
